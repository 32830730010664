/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import MobileRouteError from './Containers/MobileRouteError';
import isMobile from './VoxPlugin/Utils/mobilecheck';

const ReactLazyPreload = (importStatement) => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};
export const VoxChannels = ReactLazyPreload(() => import('./Containers/VoxChannels'));
export const Users = ReactLazyPreload(() => import('./Containers/Users'));
export const AccountSettings = ReactLazyPreload(() => import('./Containers/AccountSettings'));
export const Notifications = ReactLazyPreload(() => import('./Containers/Notifications'));

const Dashboard = React.lazy(() => import('./Containers/Main/Dashboard'));
const TeamUsers = React.lazy(() => import('./Containers/TeamUsers'));
const ResetPassword = React.lazy(() => import('./Containers/Auth/ResetPassword'));
const UpdatePassword = React.lazy(() => import('./Containers/Auth/UpdatePassword'));
const Welcome = React.lazy(() => import('./Containers/Welcome'));
const AddPhoneNumbers = React.lazy(() => import('./Containers/AddPhoneNumber'));
const ManageNumbers = React.lazy(() => import('./Containers/ManageNumbers'));
const PhoneNumbers = React.lazy(() => import('./Containers/PhoneNumbers'));
const ListRingGroups = React.lazy(() => import('./Containers/ListRingGroups'));
const CreateVoxChannel = React.lazy(() => import('./Containers/CreateVoxChannel'));
const AccountDeleted = React.lazy(() => import('./Containers/AccountDeleted'));
const PublicHostedPage = React.lazy(() => import('./Containers/PublicHostedPage'));
const PublicSharedMessage = React.lazy(() => import('./Containers/PublicSharedMessage'));
const WebRecording = React.lazy(() => import('./Containers/WebRecorder'));
const Error404Page = React.lazy(() => import('./Containers/Error404Page'));
const ZapierAuthenticator = React.lazy(() => import('./Containers/Zapier/ZapierAuthenticator'));
const Zapier = React.lazy(() => import('./Containers/Zapier/Zapier'));
const VoxMessage = React.lazy(() => import('./Containers/VoxMessage'));
const ChoosePassword = React.lazy(() => import('./Containers/Auth/ChoosePassword'));
const VoxChannelEditor = React.lazy(() => import('./Containers/VoxChannelEditor'));
const AddRingGroup = React.lazy(() => import('./Containers/AddRingGroup'));
const EditRingGroup = React.lazy(() => import('./Containers/EditRingGroups'));
const Otp = React.lazy(() => import('./Containers/Auth/Otp'));
const Inbox = React.lazy(() => import('./Containers/Inbox'));
const ShowCase = React.lazy(() => import('./Containers/ShowCase'));
const Connect = React.lazy(() => import('./Containers/Connect'));
// loadable before use of Lazy
const Channel = loadable(() => import('./VoxPlugin/Containers/channel'));
const Signup = loadable(() => import('./Containers/Auth/Signup'));
const Signin = loadable(() => import('./Containers/Auth/Signin'));
const ForgottenPassword = loadable(() => import('./Containers/Auth/ForgottenPassword'));
const VoxReply = loadable(() => import('./Containers/VoxReply'));
const ShareVox = loadable(() => import('./Containers/ShareVox'));

const LoginRegister = styled.div`
  background: #F8F8F8 !important;
  padding-bottom: 30px;
  height: calc(100vh) !important;
  overflow-y: scroll;
  .login-register {
    padding-top: 45px !important;
  }
  .logo {
    margin: 0px auto !important;
  }
`;

const AppRoute = ({
  component: Component, layout: Layout, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);
const UnauthenticatedLayout = (props) => (
  <>
    <MetaTags>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </MetaTags>
    <LoginRegister className="login-register-bg">
      <div className="login-register">
        {props.children}
      </div>
    </LoginRegister>
  </>
);

const EndCustomerLayout = (props) => (
  <div>
    <MetaTags>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </MetaTags>
    {props.children}
  </div>
);

const NoMatch = () => (
  <Error404Page />
);

const MainRouter = ({ channel }) => (
  <Switch>
    {/* Un-authenticated Routes */}
    <AppRoute exact path="/" layout={UnauthenticatedLayout} component={Signup}>
      <Redirect to="/signup" />
    </AppRoute>
    <AppRoute exact path="/signup" layout={UnauthenticatedLayout} component={Signup} />
    <AppRoute exact path="/otp/:code?" layout={UnauthenticatedLayout} component={Otp} />
    <AppRoute exact path="/login" layout={UnauthenticatedLayout} component={Signin} />
    <AppRoute exact path="/forgotten-password" layout={UnauthenticatedLayout} component={ForgottenPassword} />
    <AppRoute exact path="/reset-password" layout={UnauthenticatedLayout} component={ResetPassword} />
    <AppRoute exact path="/update-password" layout={UnauthenticatedLayout} component={UpdatePassword} />
    <AppRoute exact path="/Choose-Password" layout={UnauthenticatedLayout} component={ChoosePassword} />
    <AppRoute exact path="/welcome" layout={UnauthenticatedLayout} component={Welcome} />
    <AppRoute exact path="/account-deleted" layout={EndCustomerLayout} component={AccountDeleted} />

    {/* Un-authenticated Routes for End Customer */}
    <AppRoute exact path="/vox-reply/:id" layout={EndCustomerLayout} component={VoxReply} />
    <AppRoute exact path="/share/:id" layout={EndCustomerLayout} component={ShareVox} />
    <AppRoute exact path="/public-hosted-channel/:id" layout={EndCustomerLayout} component={PublicHostedPage} />
    <AppRoute exact path="/public-hosted-message/:id" layout={EndCustomerLayout} component={PublicSharedMessage} />
    <AppRoute exact path="/web-recording" layout={EndCustomerLayout} component={WebRecording} />
    <AppRoute exact path="/vox/:id" layout={EndCustomerLayout} component={VoxMessage} />
    {!isMobile.any()
      ? (
        <>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/team-users/:id?" component={TeamUsers} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/add-numbers" component={AddPhoneNumbers} />
          <Route exact path="/manage-phone-numbers" component={ManageNumbers} />
          <Route exact path="/phone-numbers" component={PhoneNumbers} />
          <Route exact path="/ring-groups" component={ListRingGroups} />
          <Route exact path="/add-ring-groups" component={AddRingGroup} />
          <Route exact path="/edit-ring-groups" component={EditRingGroup} />
          <Route exact path="/vox-channels" render={(props) => <VoxChannels channel={channel} {...props} />} />
          <Route exact path="/account-settings" component={AccountSettings} />
          <Route exact path="/Notifications" component={Notifications} />
          <Route exact path="/channel/:id" component={Channel} />
          <Route exact path="/create-vox-channel" component={CreateVoxChannel} />
          <Route exact path="/vox-channel-editor/:id?" render={(props) => <VoxChannelEditor channel={channel} {...props} />} />
          <Route exact path="/inbox" render={(props) => <Inbox channel={channel} {...props} />} />
          <Route exact path="/zapier" render={(props) => <ZapierAuthenticator channel={channel} {...props} />} />
          <Route exact path="/zapier-intergration" render={(props) => <Zapier channel={channel} {...props} />} />
          <Route exact path="/showcase/:token" render={(props) => <ShowCase channel={channel} {...props} />} />
          <Route exact path="/connect" component={Connect} />
        </>
      ) : (
        <>
          {/* Authenticated Routes MOBILE */}
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/mobile-access" render={(props) => <MobileRouteError isSignUp {...props} />} />
          <Route exact path="/team-users/:id?" component={MobileRouteError} />
          <Route exact path="/users" component={MobileRouteError} />
          <Route exact path="/add-numbers" component={MobileRouteError} />
          <Route exact path="/manage-phone-numbers" component={MobileRouteError} />
          <Route exact path="/phone-numbers" component={MobileRouteError} />
          <Route exact path="/ring-groups" component={MobileRouteError} />
          <Route exact path="/add-ring-groups" component={MobileRouteError} />
          <Route exact path="/edit-ring-groups" component={MobileRouteError} />
          <Route exact path="/vox-channels" render={(props) => <MobileRouteError channel={channel} {...props} />} />
          <Route exact path="/account-settings" component={MobileRouteError} />
          <Route exact path="/channel/:id" component={MobileRouteError} />
          <Route exact path="/create-vox-channel" component={MobileRouteError} />
          <Route exact path="/vox-channel-editor/:id?" render={(props) => <MobileRouteError channel={channel} {...props} />} />
          <Route exact path="/inbox" render={(props) => <Inbox channel={channel} {...props} />} />
          <Route exact path="/zapier" render={(props) => <MobileRouteError channel={channel} {...props} />} />
          <Route exact path="/zapier-intergration" render={(props) => <MobileRouteError channel={channel} {...props} />} />
          <Route exact path="/showcase/:token" render={(props) => <ShowCase channel={channel} {...props} />} />
        </>
      )}

    <Route component={NoMatch} />
  </Switch>

);

export default MainRouter;
