import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import invalidRoute from '../Assets/img/invalidRoute.svg';
import mobileSignup from '../Assets/img/mobileSignup.svg';
import isMobile from '../VoxPlugin/Utils/mobilecheck';

const InvalidRoute = styled.div`
  text-align: center;
`;
const PageImage = styled.img`
  text-align: center;
  position: absolute;
  top:${(props) => (props.isSignUp ? '20%' : '47%')};
  display: block;
  margin: 0 auto;
  left: 8%;
  width: 85%;
`;
const BackLink = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size:${(props) => (props.isSignUp ? '15px' : '40px')};
  line-height: 16px;
  color: #007bff;
  text-align: center;
  top: ${(props) => (props.isSignUp ? '40rem' : '100rem')};
  position: relative;
  width: 30%;
  display: block;
  @media only screen and (orientation: landscape) {
    top: ${(props) => (props.isSignUp ? '65rem' : '50rem')};
  }
`;

const MobileRouteError = (props) => {
  const { isSignUp } = props;
  const history = useHistory();
  if (isMobile.any()) {
    window.addEventListener('popstate', (event) => {
      console.log(event);
      window.localStorage.setItem('backCheck', '0');
    });
  }
  const goToInbox = () => {
    history.push('/inbox');
  };
  document.getElementById('scroll-body').style.overflowY = 'scroll';
  return (

    <InvalidRoute>
      {!isSignUp ? (
        <div className="row">
          <PageImage src={invalidRoute} alt="Please access on Desktop" className="col-12" />
          <BackLink href="#" onClick={goToInbox} className="col-12">
            <i className="fas fa-long-arrow-alt-left" />
            {' '}
            Back
          </BackLink>
        </div>
      ) : (
        <div className="row">
          <PageImage src={mobileSignup} isSignUp={isSignUp} alt="" className="col-12" />
          <BackLink href="#" onClick={goToInbox} className="col-12" isSignUp={isSignUp}>
            <i className="fas fa-long-arrow-alt-left" />
            {' '}
            Inbox
          </BackLink>
        </div>
      )}
    </InvalidRoute>
  );
};

MobileRouteError.propTypes = {
  isSignUp: PropTypes.bool,
};

MobileRouteError.defaultProps = {
  isSignUp: false,
};

export default MobileRouteError;
