import ApolloClient from 'apollo-boost';
import Cookies from 'universal-cookie';
import { isEmpty } from 'lodash/fp';
import { USER_TOKEN_KEY } from '../Constants/Cookie';

const Client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  resolvers: {},
  request: (operation) => {
    const cookies = new Cookies();
    const token = cookies.get(USER_TOKEN_KEY);
    operation.setContext({
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        format: 'json',
        token: isEmpty(token) ? '' : token,
      },
    });
  },
});
Client.defaultOptions = {
  watchQuery: {
    errorPolicy: 'all',
  },
};

export default Client;
