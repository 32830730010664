import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import languageEN from './locate/en/translate.json';
import languageES from './locate/es/translate.json';
import languageFR from './locate/fr/translate.json';
import languageGER from './locate/ger/translate.json';
import languageIT from './locate/it/translate.json';
import languagePT from './locate/pt/translate.json';
import languageSL from './locate/sl/translate.json';
import languageUR from './locate/ur/translate.json';
import languageJA from './locate/ja/translate.json';
import languagePO from './locate/po/translate.json';
import languageSP from './locate/sp/translate.json';
import languageNL from './locate/nl/translate.json';
import languageRU from './locate/ru/translate.json';
import languageZH from './locate/zh/translate.json';
import languageZHTW from './locate/zh-TW/translate.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      es: languageES,
      fr: languageFR,
      ger: languageGER,
      it: languageIT,
      pt: languagePT,
      sl: languageSL,
      ur: languageUR,
      ja: languageJA,
      po: languagePO,
      sp: languageSP,
      nl: languageNL,
      ru: languageRU,
      zh: languageZH,
      'zh-TW': languageZHTW,
    },
    /* default language when load the website in browser */
    lng: 'en',
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: 'en',
    /* debugger For Development environment */
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
