import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import Client from './GraphQL/Client';
import MainRouter from './MainRouter';

const Layout = React.lazy(() => import('./Layout'));

const App = () => (
  <BrowserRouter>
    <ApolloProvider client={Client}>
      {window.location.href.includes('/vox-reply') || window.location.href.includes('/channel')
        ? (
          <Suspense fallback={<div />}>
            <MainRouter />
          </Suspense>
        )
        : (
          <Suspense fallback={<div />}>
            <Layout>
              <MainRouter />
            </Layout>
          </Suspense>
        )}
    </ApolloProvider>
  </BrowserRouter>
);
export default App;
